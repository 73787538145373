import {Component, HostBinding} from '@angular/core';
import {Router} from '@angular/router';


import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class') classlist = 'dt-root';

  route: string;
  settings: any;


  constructor(private router: Router,
              private i18n: NzI18nService,
              ) {

    this.i18n.setLocale(en_US);

  }

}
