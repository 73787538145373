import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from './api.service';
import {AuthService} from './Auth.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    public route: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(((resolve, reject) => {

        console.log('AUTH GUARD READY');

        const promiseArray = [
           this.auth.getToken(),
           this.auth.getExpiry()
       ];

        Promise.all(promiseArray).then((res) => {
          console.log('AUTH GUARD DONE');

          if (this.checkExpiry(res[1])){
            this.auth.token.next(res[0]);
            resolve(true);
          }else{
            console.log('EXPIRED');
            this.router.navigate(['signin'], {queryParams: {returnUrl: state.url}});
          }

       }).catch((err) => {
          console.log('NO TOKEN FOUND');
          this.router.navigate(['signin'], {queryParams: {returnUrl: state.url}});
        });

    })); // END PROMISE

  }

  checkExpiry(date): boolean{

    const now = moment();
    const expires = moment(date);
    return moment(now).isBefore(expires);

  }

}
