export const environment = {
  production: true,
  apiURL: 'https://fetch-api.azurewebsites.net/',
  site: 'https://fetch.je/',
};

// export const environment = {
//   production: true,
//   apiURL: 'https://fetch-api-uat.azurewebsites.net/',
//   site: 'https://uat.fetch.je/',
// };