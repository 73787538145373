import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  token = new BehaviorSubject<any>(false);
  user = new BehaviorSubject<any>(false);
  httpOptions: any;
  isActivatedNew = false;

  constructor(
    public router: Router,
    public http: HttpClient,
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

  }

  tokenObservable(): Observable<any> {
    return this.token.asObservable();
  }

  userObservable(): Observable<any> {
    return this.user.asObservable();
  }

  forgotRequest(data): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
          .post(environment.apiURL + 'oAuth/forgot', data, options)
          .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              },
          );
    });

  }

  forgotChange(data): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
          .post(environment.apiURL + 'oAuth/forgot/change', data, options)
          .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              },
          );
    });

  }

  forgotCheck(data): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
          .post(environment.apiURL + 'oAuth/forgot/check', data, options)
          .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              },
          );
    });

  }

  changeEmailRequest(code): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
          .post(environment.apiURL + 'oAuth/emailChangeValidate', {guid: code}, options)
          .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              },
          );
    });

  }

  specialSignIn(token): any {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
          .post(environment.apiURL + 'oAuth/specialLogin', {
            access_token: token
          }, options)
          .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              },
          );
    });

  }

  doLogin(data): any {

    data.grant_type = 'password';
    data.client_id = 'appClient';
    data.client_secret = '098f6bcd4621d373cade4e832627b4f6';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'oAuth/login', data, options)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  logOut(): any {

    return new Promise((resolve, reject) => {

      localStorage.clear();
      this.user.next(false);
      this.token.next(false);
      this.router.navigateByUrl('/signin');

    });

  }

  doActivate(data, id): any {

    data.grant_type = 'password';

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'oAuth/activate/' + id, data, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  doRegister(data): any {

    data.grant_type = 'password';
    data.client_id = 'appClient';
    data.client_secret = '098f6bcd4621d373cade4e832627b4f6';

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'oAuth/register/vendor', data, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }


  getToken(): any {

    return new Promise((resolve, reject) => {

      this.desktopAuthCheck('token').then((res) => {
        console.log('Desktop ' + res.access_token);
        resolve(res.access_token);
      }).catch((err) => {
        console.log('no mobile token found');
        reject(false);
      });

    });

  }

  getExpiry(): any {

    return new Promise((resolve, reject) => {

      this.desktopAuthCheck('token').then((res) => {
        console.log('Desktop ' + res.expires);
        resolve(res.expires);
      }).catch((err) => {
        console.log('no desktop expiry found');
        reject(false);
      });

    });

  }

  getUser(): any {

    return new Promise((resolve, reject) => {

      this.desktopAuthCheck('user').then((res) => {
        console.log('User found ' + res.email);
        resolve(res);
      }).catch((err) => {
        console.log('No user found');
        reject(false);
      });

    });

  }

  desktopAuthStore(data): any {

    return new Promise((resolve, reject) => {
      localStorage.setItem('token', JSON.stringify(data.token));
      localStorage.setItem('user', JSON.stringify(data.user));
      resolve(true);
    });

  }


  desktopAuthCheck(item): any {

    return new Promise((resolve, reject) => {
      resolve(JSON.parse(localStorage.getItem(item)));
    });

  }


}
